/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

//window.axios = require('axios');
import axios from 'axios';

export default function bootstrap() {
  
  window.axios = axios;
  //window._ = require('lodash');

  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  /**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

  let token = document.head.querySelector('meta[name="csrf-token"]');

  if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
  } else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
  }

  // Object compare method
  Object.compare = function (obj1, obj2) {
    let p;
    //Loop through properties in object 1
    for (p in obj1) {
    //Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
      switch (typeof (obj1[p])) {
      //Deep compare objects
      case 'object':
        if (!Object.compare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case 'function':
        if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
        break;
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
      }
    }
    //Check object 2 for any extra properties
    for (p in obj2) {
      if (typeof (obj1) == 'undefined' || typeof (obj1[p]) == 'undefined') return false;
    }
    return true;
  };

  /**
 * Define the chunk method in the prototype of an array
 * that returns an array with arrays of the given size.
 *
 * @param chunkSize {Integer} Size of every group
 */
  Object.defineProperty(Array.prototype, 'chunk', {
    value(chunkSize) {
      let temporal = [];
      for (let i = 0; i < this.length; i += chunkSize) {
        temporal.push(this.slice(i, i + chunkSize));
      }
      return temporal;
    }
  });
}
