/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import App from './views/App.vue';

import bootstrap from './bootstrap';
//import { configureCompat } from 'vue';
import { createApp } from 'vue';

import { createPinia, PiniaVuePlugin } from 'pinia'
import { useRootStore } from './store/store';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

//configureCompat({
//  MODE:3,
//  ATTR_FALSE_VALUE: 'suppress-warning',
//});


bootstrap();

//Vue.use(PiniaVuePlugin)
export const pinia = createPinia()
//Vue.use(pinia);

import { createI18n } from 'vue-i18n';
import '@mdi/font/css/materialdesignicons.css';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

//import { mdi } from 'vuetify/iconsets/mdi';
//import { aliases, md } from 'vuetify/iconsets/md'; // GOOGLE

//import 'vuetify/styles'
//import { createVuetify } from 'vuetify'
//import * as components from 'vuetify/components'
//import * as directives from 'vuetify/directives'
//import { VTimePicker } from 'vuetify/labs/VTimePicker';

const customThemeLight = {
  dark: false,
  colors : {
    primary: '#418FDE',
    secondary: '#888B8D',
    accent: '#FF7F32',
    'accent-1': '#54A1ff',
    error: '#E4002B',
    info: '#2196F3',
    success: '#43B02A',
    warning: '#FFC107',
    btnBg: '#f5f5f5',
  }
};

const customThemeDark = {
  dark: true,
  colors : {
    primary: '#418FDE',
    secondary: '#888B8D',
    accent: '#FF7F32',
    'accent-1': '#54A1ff',
    error: '#E4002B',
    info: '#2196F3',
    success: '#43B02A',
    warning: '#FFC107',
    btnBg: '#f5f5f5',
  }
};

/*
export const vuetify = createVuetify({
  components:{ ...components, VTimePicker, VueDatePicker },
  directives,
  icons: {
    //    iconfont: 'mdiSvg',
    //    defaultSet: 'mdi',
    defaultSet: 'md',
    aliases,
    sets: {
      md,
      mdi,
    },
  },
  theme: {
    defaultTheme: 'customThemeLight',
    themes: {
      customThemeLight,
      customThemeDark
    }
  }
});
*/

//import VeeValidate, { ValidationProvider } from 'vee-validate';
//import { ValidationProvider } from 'vee-validate';
//import validateEn from 'vee-validate/dist/locale/en';
//import validateDe from 'vee-validate/dist/locale/de';
//import validateEs from 'vee-validate/dist/locale/es';

export const i18n = new createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      "verify-title": "Reset/Verify your account",
      "verify-success": "Your password has been reset.",
      "verify-password-label": "Password",
      "verify-password-confirm-label": "Confirm Password",
      "verify-error": "An error has occurred please contact support.",
      "verify-btn-submit": "Submit",

      "app-forgotten-success": "We have sent you an email to set a new password.",
      "app-forgotten-title": "Recover Password",
      "app-forgotten-email-label": "Email",
      "app-forgotten-confirm-email-label": "Confirm Email",
      "app-forgotten-btn-submit": "Submit"
    },
    de: {
      "verify-title": "Setzen Sie Ihr Konto zurück / überprüfen Sie es",
      "verify-success": "Dein Passwort wurde zurück gesetzt.",
      "verify-password-label": "Passwort",
      "verify-password-confirm-label": "Kennwort bestätigen",
      "verify-error": "Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support.",
      "verify-btn-submit": "einreichen",

      "app-forgotten-success": "Wir haben Ihnen eine E-Mail gesendet, um ein neues Passwort festzulegen.",
      "app-forgotten-title": "Passwort-Wiederherstellung",
      "app-forgotten-email-label": "E-mail",
      "app-forgotten-confirm-email-label": "E-Mail-Adresse bestätigen",
      "app-forgotten-btn-submit": "Einreichen"
    },
    es: {
      "verify-title": "Restablecer / verificar su cuenta",
      "verify-success": "Tu contraseña ha sido restablecida.",
      "verify-password-label": "Contraseña",
      "verify-password-confirm-label": "Confirmar contraseña",
      "verify-error": "Se ha producido un error, póngase en contacto con soporte.",
      "verify-btn-submit": "Enviar",

      "app-forgotten-success": "Le hemos enviado un correo electrónico para establecer una nueva contraseña.",
      "app-forgotten-title": "Recuperar contraseña",
      "app-forgotten-email-label": "Correo electrónico",
      "app-forgotten-confirm-email-label": "Confirmar correo electrónico",
      "app-forgotten-btn-submit": "Enviar"
    }
  }
});

/* TODO - MIG  2024-04-10 FIXME
Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    en: validateEn,
    es: validateEs,
    de: validateDe
  }
});
*/

//Vue.component('ValidationProvider', ValidationProvider);
/*
export function setup_vue(component, temp) {
  console.log("Setup --FIXME "+temp, component);
  let app = createApp({
    components: {
      //      'app': component,
      App
//      component,
    },
    mounted() {
      const rootStore = useRootStore();
      rootStore.boot();
    },
    template: temp
  });
  app.use(pinia);
  app.use(vuetify);
  app.use(i18n);
  //  app.component('ValidationProvider', ValidationProvider);
  return app;
};

*/
